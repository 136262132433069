.item {
  margin: 12px 0;
  background: #ededed;
  display: flex;
}

.link{
  padding: 8px 12px;
  display: block;
  flex-grow: 1;
}

.genre {
  background: black;
  color: white;
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  padding: 2px 6px;
  vertical-align: middle;
}

.name {
  text-align: left;
  font-size: 18px;
}

.date {
  font-weight: normal;
  text-align: left;
}
