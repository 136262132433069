.sideBarContainer {
  position: fixed;
  height: calc(100vh - 60px);
  width: 80%;
  max-width: 350px;
  top: 60px;
  background:black;
  right: 0;
  transition: transform 300ms linear;
  will-change: transform;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 12px;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuLink {
  text-align: center;
  font-weight: bolder;
  color: black;
  background: white;
  font-size: 18px;
  padding: 10px 14px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.socialMediaBar {
  justify-content: center;
  margin-bottom: 36px;
}
