.contentContainer {
  padding: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.titleSection {
  display: flex;
  width: 100%;
  align-items:flex-start;
  justify-content: space-between;
}
.shareButton {
  margin-top: 15px;
  margin-left: 8px;
}
.secondaryNav {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  align-items: left;
  flex-direction: column;
}
.ticketButton {
  max-width: 400px;
  flex-shrink: 0;
  flex-grow: 1;
}
.venueLink {
  max-width: 404px;
  flex-shrink: 0;
  flex-grow: 1;
  line-height: 16px;
  padding: 6px 10px;
  box-sizing: border-box;
  margin-bottom: 8px;
}
.venueName {
  background: black;
  color: white;
  padding: 3px 8px;
  margin-left: 10px;
}

.date {
  text-transform: uppercase;
  margin-bottom: 6px;
  top: 74px;
  background: white;
  z-index: 1;
}

.doors {
  margin: 0;
  margin-bottom: 12px;
}

.title {
  text-align: left;
  margin-bottom: 6px;
  font-size: 22px; 
  line-height: 1;
  word-break:break-word;
}


.locationLink {
  margin-left: -6px;
  margin-bottom: 24px;
}

.section {
  margin: 12px 0;
}

.similar {
  margin: 12px 0;
}

.description {
  margin: 12px 0;
  p {
    margin: 12px 0;
  }
}

