.section {
  padding: 12px;
  box-sizing: border-box;
}

.titleHeader {
  margin: 12px;
}

.pagination {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  justify-content: center;
}

.backButton {
  border: 2px solid white;
  margin: 0 10px 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 3;
}

.accHeader {
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}

.h1Name {
  margin: 12px;
  font-size: 22px;
}

@keyframes transitionIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tranistionIn {
  animation: transitionIn linear 1s;
  animation-fill-mode: forwards;
}

.randomLine {
  display: block;
  text-align: center;
}
