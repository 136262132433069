.link {
  background: black;
  width: 30px;
  height: 30px;
  display: flex;
  svg {
    width: 30px;
    fill: white;
  }
}

.linkInverted {
  background: white;
  width: 30px;
  height: 30px;
  display: flex;
  svg {
    width: 30px;
    fill: black;
  }
}
