.icon {
  width: 18px;
  height: 18px;
  margin-left: 12px;
}
.primaryButton {
    background: white;
    min-height: 30px;
    text-transform: uppercase;
    display: inline-flex;
    justify-content: center;
    color: black;
    align-items: center;
    font-weight: bolder;
    justify-content: center;
    line-height: 30px;
    font-size: 16px;
     
    border: black solid 2px;
}
