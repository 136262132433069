.venueName{ 
  margin-bottom: 3px;
  margin-top: 0;
}

.address {
  text-transform: uppercase;
  margin-bottom: 0;
}
.marker {
  width: 30px;
  height: 30px;
}
.locationLink {
  display: flex;
  align-items: center;
}
