:global {
  body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    max-width: 1000px;
    margin: auto;
    background: white;
    border: none;
    @media screen and (min-width: 1000px) {
      background: #e5e5e5;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  li {
    list-style-type: none;
    box-sizing: border-box;
  }
  h6,
  h5,
  h4,
  h3,
  h2 {
    margin: 0;
    box-sizing: border-box;
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    box-sizing: border-box;
  }
  h3 {
    line-height: 16px;
    font-size: 15px;
  }

  h4,
  h5,
  h6 {
    line-height: 1;
    font-size: 12px;
    margin: 2px 0;
  }
  p {
    margin: 0;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  a {
    text-decoration: none;
    color: black;
  }
  div {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

.container {
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: 35px;
  background: white;
  position: relative;
}
