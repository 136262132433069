.header {
  background:black;
  position:fixed;
  height: 64px;
  max-height: 64px;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 2;
 }

.logoLink
{
  display: block;
  position: relative;
  transform-origin: left top;
  top: 50%;
  transform: scale(0.5, 0.5) translateY( -50%);
  align-self: start;
}
