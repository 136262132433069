.nameHeading {
  text-align: left;
  padding: 12px;
  font-size: 20px; 
   
}
.listItem{
   
  &:not(:first-child){
    margin-top: 36px;
  }
  &:not(:last-child){
    margin-bottom: 36px;
  }
  border-top: 4px solid black;
  border-bottom: 4px solid black;
}

.wrapperImg {
  width:100%;
  overflow: hidden;
  height: 30vh;
  background:grey;
  position: relative;
}

.location {
  padding: 12px;
  margin-left: -6px;
}
