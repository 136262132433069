.conatiner {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  margin: 0;
  padding: 0;
}

.item {
  flex-shrink: 0;
  border: 2px solid black;
  width: 180px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
      margin-right: 12px;
  }
}
.itemLink {
  display: block;
  padding-bottom: 12px;
  height: 100%;
}

.itemImg {
  width: 100%;
  height: 200px;
}

.itemTitle {
  
  padding: 0 6px;
  margin-bottom: 5px;
}

.itemPlace {
 
  padding: 0 6px;
}
.itemDate {
  width: 100%;
  padding: 6px;
}
