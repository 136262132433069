.item {
  margin-bottom: 12px;
  overflow: hidden;
}

.inThePast {
  opacity: 0.7;
}



.link{
  display: flex;
  flex-direction: row;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 6px;
  background: #ededed;
  justify-content: space-between;
}

 

.inThePastTime {
  background:  #e1e1e1;
  color: grey;
}

.inTheFutureTime {
  background: black;
  color: white;
}

.time {
  width: 45px;
  max-width: 45px;
  height: auto;
  min-height: 45px;
  padding: 6px;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.day {
  font-size: 12px;
  text-align: center;
  font-weight: normal;
}

.monthDay {
  font-size: 18px;
  text-align: center;
  text-transform: lowercase;
}
.month {
  font-size: 12px;
  text-align: center;
}
