.htmlContent {
  overflow: hidden;
  font-size: 14px;
  margin: 12px 0;
  img {
    display: block;
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    margin: auto;
    padding: 4px; 
    max-width: calc(100% - 14px);
    max-height: 50vh;
    min-height: 60px;
    border: 3px solid black;
  }
  a {
    color: white;
    background: black;
    padding: 4px 4px;
    margin: 4px 0;
    display: inline-block;
    &:after {
      margin-left: 7px;
      top: 2px;
      position: relative;
      content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWklEQVQ4jWNgGDTgPxpAEm9Al0OWJ2gALkNgckxYHMPOwMDATLYXsIiT5gUCmhtgYjA1LCQ4spGRkbEBajDJXmggaDxOvxFQhy0WSAIUG4ARiMR4g6ouGHgAAE8Ls3ASM9MUAAAAAElFTkSuQmCC");
    }
  }
}
