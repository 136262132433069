.backButton{
background: black;
width: 30px;
height: 30px;
display: flex;
justify-content: center;
color: white;
font-weight: bolder;
line-height: 30px;
font-size: 30px;
text-align: center;
box-sizing: content-box;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
