.about {
  section {
    .pContact {
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 1.3rem;
      margin-bottom: 0.3rem;
    }

    margin-bottom: 1rem;
    margin-top: 1rem;

    p {
      font-size: 1.2rem;
    }

    .archiveLink {
      text-decoration: underline;
      font-weight: bold;
      font-size: 1.4rem;
    }
  }


}
