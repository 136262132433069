 
@keyframes pulse1 {
  0% {
    background:grey;
  }
  50% {
    background: lightgray;
  }
  100% {
    background: grey;
  }
}


 
@keyframes transitionIn {
  0% {
    opacity:0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  width:100%;
  overflow: hidden;
  height: 40vh;
  background:grey;
  position: relative;
}

.imageAnimation {
  opacity:0;
  animation: transitionIn linear 0.4s;
  animation-fill-mode: forwards;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
  display: block;
 }

.loader {
  width: 100%;
  height: 100%;
  background: grey;
  -webkit-animation: pulse1 3s linear infinite;
	-moz-animation: pulse1 3s linear infinite;
	-ms-animation: pulse1 3s linear infinite;
	animation: pulse1 3s linear infinite;
}
