.logoContainer {
  display: inline-block;
  font-size: 24px;
}
.letter {
  color: black;
  font-weight: bold;
  background: white;
  transition: all 0.1s ease-in;
  display: inline-block;
}
.topLine {
  text-align: center;
  background: black;
  margin-bottom: 10px;
}

.bottomLine {
  background: black;
}
