.location {
  margin-left: -6px;
  margin-bottom: 12px;
  padding-left: 12px;
}

.upcoming {
  margin: 12px 0;
  padding: 6px 12px;
  text-align: center;
  font-size: 16px;
  background: grey;
}
