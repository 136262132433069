.pagination {
  button {
    cursor: pointer;
    padding: 0.5rem;
    border: solid;
    margin-left: -1px;

    &:hover:enabled {
      background: black;
      color: white;
      border: solid black;
    }

    &:disabled {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .dots {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }

  .prev,
  .next {
    border: solid;
  }

  .number {
    width: 3rem;
  }

  .active {
    font-weight: bold;
    background-color: darkgray;
  }

}
