.notice {
  background: grey;
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  left: 0;
  z-index: 2;
  padding: 12px 12px 12px 60px;
  margin: 12px 0;
  line-height: 1.2;
  text-align: center;
  width: 100&;
  overflow: hidden;
  box-sizing: border-box;
  text-align: left;
}

.time {
  background: black;
  color: white;
  padding: 3px 16px;
  display: inline-block;
}
