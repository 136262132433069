.stickyDate {
  position: sticky;
  position: -webkit-sticky;
  top: 64px;
  padding: 12px;
  background: white;
  border: 1px dashed grey;
  color: dimgrey;
  display: flex;
  justify-content: center;
}

.tonight {
  background: black;
  color: white;
  padding: 4px;
  font-size: 10px;
  margin-left: 12px;
}

.listItem {
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  background: #ededed;
}

.link {
  padding: 8px 12px;
  display: block;
  width: 100%;
}

.title {
  margin: 0;
}

.genre {
  background: black;
  color: white;
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  padding: 2px 6px;
}
