.footer {
  background: black;
  color: white;
  padding: 10px 10px 60px;
}

.link {
  color: white;
}
.socialMediaBar {
  margin: 12px 0;
}

 
