.dontShow {
 display: none!important;
}
 
.shareButton {
  display: flex;
  border: 2px solid black;
  padding: 5px;
  width: 38px;
  height: 46px
}
.icon {
  height: 100%;
}
